<form [formGroup] = "csvForm">
    <div class="modal-body">
        <div class="row">
           
            <div class="col-md-12">
                <div class="form-group">
                    <label for=""> Upload Csv File </label>
                    <div class="box-label">
                        <input type="file" id="img" name="img" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        class="form-control" placeholder=""  (change)="onchange1($event)">
                        <!-- <label><i class="fa fa-upload" aria-hidden="true"></i></label> -->
                        <mat-error *ngIf="submitted && !docImage1" class="invalid-feedback">
                            File  is required.
                          </mat-error>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div class="modal-footer">
        <button type="button" (click)="submitImportFile()" class="btn btn-primary" >
            Submit
        </button>
        <button type="button" (click)="close()" class="btn btn-primary">
            Close
        </button>
    </div>
    </form>
