import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-export-component',
  templateUrl: './export-component.component.html',
  styleUrls: ['./export-component.component.scss']
})
export class ExportComponentComponent implements OnInit {
  imageFile1 :any
  docImage1:any
  isUpdatedImage: boolean;
  public submitted: boolean = false;
  newImage1: any;
  csvForm:FormGroup

  constructor(private http: HttpService, private dialog: MatDialog,private cm:CommonService, private fb: FormBuilder,) { 
    this.csvForm = this.fb.group({
      img:[]
      })
  }

  ngOnInit(): void {
  }



  onchange1(event) {
    const allowedTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    const file = event.target.files[0];
    const fileType = file.type;

    if (!allowedTypes.includes(fileType)) {
      this.cm.presentsToast('error','top-right',"Invalid file type. Please select a CSV, XLSX, or XLS file.");
      // Clear the file input
      event.target.value = null;
      return;
    }
    console.log(event)
    this.isUpdatedImage = true;
    this.docImage1 = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.newImage1 = event.target.result;
        console.log(this.newImage1,'nnn')
      };
      
    }
  }
  
  submitImportFile()
  {
    this.submitted = true;
    // if (this.csvForm.valid) {
    //   this.cm.presentsToast('success','top-right','Please upload file first')
    // }
    if( this.submitted && this.docImage1 ) {
      var fd = new FormData();
      fd.append("file",this.docImage1);
       this.http
        .postRequest('admin/product/import',fd,)
        .subscribe(
          (response: any) => {
            if (response.statusCode == 200) {
              this.dialog.closeAll();
              this.cm.presentsToast('success','top-right',"Csv added successfully");
              this.submitted = false;
              // this.getTutorById();
            }
          },
          (error) => {
            this.cm.presentsToast('error','top-right',error);
          }
        );
    }
  
  }

  close(){
    this.dialog.closeAll()
  }

}
