import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-new-service',
  templateUrl: './new-service.component.html',
  styleUrls: ['./new-service.component.scss']
})
export class NewServiceComponent implements OnInit {
  addServiceForm:FormGroup
  submitted: boolean = false
  slideConfig1 = {"slidesToShow": 4, "slidesToScroll": 1};
  allService: any;
  allServiceCategory: any;
  providerid: string;
  listOfStaff: any;
  allDuration: any;
  data:any
  deleteList: any[]=[];
  checkSingle:any[]=[]
  showImageCheck: boolean=false;
  singleCheck:boolean = false
  showImageParticularCheck:boolean = false
  selectSingle:boolean = false
  providertype: string;
  allservices: any;
  categoryId: any;
  serviceId:any
  iddd:any
  subservice: any;
  role: string;
  storeId: any =[];
  storecheckIds: any =[];
  combine:any=[]
  storeAllId: any = [];
  staffId: any;
  constructor(private dialog: MatDialog,private http:HttpService,private fb:FormBuilder,private cm:CommonService) { }
  handleUImageErr(event,staff){
    staff.image = 'assets/img/png/No_image_available.png'
  }
  close(){
    this.dialog.closeAll()

  }
  ngOnInit(): void {
    console.log(this.data)
    this.providerid = localStorage.getItem('providerId')
    this.providertype = localStorage.getItem('providertype')
    this.role = localStorage.getItem('role')

    this.addServiceForm = this.fb.group({
      serviceCategory: ['', [Validators.required,]],
      subService: ['', [Validators.required,]],
      duration: ['', [Validators.required,]],
      price:['',[Validators.required]],
      specialPrice:[''],

    })
    this.getServiceCategoryData()
    
    this.getServiceDuration()

    if(this.data){
      this.setValue();
      this.data.staff.filter((res=>{
        this.storeId.push(res?._id) 
        this.deleteList = this.storeId       
        console.log(this.deleteList,'ssss')
      }))
    }
  }
  
  serviceChange(service){
    this.serviceId = service
    this.getListOfStaff()
  
  }

  setValue(){
    this.addServiceForm.patchValue(this.data)
    this.addServiceForm.patchValue({
      duration: this.data?.duration?._id,
      subService:this.data?.subService?._id
    })
    if(this.data?.subService?._id){
      this.serviceChange(this.data?.subService?._id)
    }
    if(this.data?.serviceCategory){
      this.categoryChange(this.data.serviceCategory)
    }
  }

  get f(): any {
    return this.addServiceForm.controls
  }

  categoryChange(category) {
    console.log("changes", category);
    this.categoryId = category;
    console.log(this.categoryId,'cccc')
    this.getServiceData(category);
    
  }

  getServiceCategoryData() {
    if(this.role == 'ADMIN' || this.role == 'SUB'){
    this.http.getRequest("admin/provider/service/categories/" + this.providertype, '').subscribe(
      (res: any) => {
        this.allServiceCategory = res.data;       
      },
      (error) => console.log(error)
    );
  }
  else if (this.role == 'PROVIDER'){
    this.http.getRequest("provider/categories", '').subscribe(
      (res: any) => {
        this.allServiceCategory = res.data;       
      },
      (error) => console.log(error)
    );
  }
  }

  getServiceData(category) {
    if(this.role == 'ADMIN'|| this.role == 'SUB'){
    this.http.getRequest("admin/provider/service/sub?providerType=" + this.providertype + "&categoryId=" + category, '').subscribe(
      (res: any) => {
        this.allService = res.data; 
    },
      (error) => console.log(error)
    );
  }
    else if(this.role == 'PROVIDER'){
      this.http.getRequest("provider/services?categoryId=" + category, '').subscribe(
        (res: any) => {
          this.allService = res.data; 
      },
        (error) => console.log(error)
      );
    }
  }

  getServiceDuration() {
    if(this.role == 'ADMIN' || this.role == 'SUB'){
    this.http.getRequest("admin/provider/service/duration", '').subscribe(
      (res: any) => {
        this.allDuration = res.data;
              },
      (error) => console.log(error)
    );
    }
    else if(this.role == 'PROVIDER'){
      this.http.getRequest("provider/duration", '').subscribe(
        (res: any) => {
          this.allDuration = res.data;
                },
        (error) => console.log(error)
      );
    }
  }


  getListOfStaff(){
    if(this.role == 'ADMIN' || this.role == 'SUB'){
      this.http.getRequestWithoutbody('admin/provider/staff?id=' + this.providerid + "&serviceId=" + this.serviceId).subscribe((res=>{
        this.listOfStaff = res['data']['data']
        if(this.listOfStaff.length === 1){
          this.listOfStaff[0].check=true;
          this.singleCheck = true;
          this.deleteList.push(this.listOfStaff[0]._id)
        }
        else{
          this.listOfStaff.filter((res=>{
            res.check = false
            if(this.storeId.length>0){
                this.storeId.filter((res1=>{
                 if(res._id == res1){
                  res.check = true
                 }
                }))
            }
            else{
              res.check = false
            }
          }))
        }
       
        
      }))
     

    }
    else if(this.role == 'PROVIDER'){
      this.http.getRequestWithoutbody('provider/staff').subscribe((res=>{
        this.listOfStaff = res['data']['data']
        if(this.listOfStaff.length === 1){
          this.listOfStaff[0].check=true;
          this.singleCheck = true;
          this.deleteList.push(this.listOfStaff[0]._id)
        }
        else{
          this.listOfStaff.filter((res=>{
            res.check = false
            if(this.storeId.length>0){
                this.storeId.filter((res1=>{
                 if(res._id == res1){
                  res.check = true
                 }
                }))
            }
            else{
              res.check = false
            }
          }))
        }        
      }))
    }
  }
  


  add(){
    if(this.role == 'ADMIN' || this.role == 'SUB'){
    this.submitted = true;
    if(this.showImageCheck == false && this.singleCheck == false )
    {
      this.submitted = false;
      this.cm.presentsToast('error', 'top-right', 'Please select Staff And All  Field')
    }
    
    if(this.submitted && this.addServiceForm.valid && (this.showImageCheck || this.singleCheck)){
      let data1 = this.addServiceForm.value;
      data1.staff = this.deleteList;
      data1.provider = this.providerid
      data1.price = parseFloat(this.addServiceForm.value.price);
      data1.specialPrice = parseFloat(this.addServiceForm.value.specialPrice);
      console.log(data1,'ddd')
    
      this.http.postRequest('admin/provider/service', data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Service Added successfully');
           this.dialog.closeAll()
           this.cm.servicelist$.next(true)
          }
       },(err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      })
    }
  }
  else if(this.role == 'PROVIDER'){
    this.submitted = true;
    if(this.showImageCheck == false && this.singleCheck == false )
    {
      this.submitted = false;
      this.cm.presentsToast('error', 'top-right', 'Please select Staff And All  Field')
    }
    if(this.submitted && this.addServiceForm.valid && (this.showImageCheck || this.singleCheck)){
      let data1 = this.addServiceForm.value;
      data1.staff = this.deleteList;
      data1.price = parseFloat(this.addServiceForm.value.price);
      data1.specialPrice = parseFloat(this.addServiceForm.value.specialPrice);
      this.http.postRequest('provider/service', data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Service Added successfully');
           this.dialog.closeAll()
           this.cm.servicelist$.next(true)
          }
       },(err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      })
    }
  }
  }

  update(){
  if(this.role == 'ADMIN' || this.role == 'SUB'){
    this.submitted = true;
    if(this.deleteList.length==0){
      this.cm.presentsToast('error','top-right','Please select Staff')
    }

    
    if(this.submitted && this.addServiceForm.valid && this.deleteList.length>0 ){
      let data1 = this.addServiceForm.value;
      data1.staff = this.deleteList;
      data1.price = parseFloat(this.addServiceForm.value.price);
      data1.specialPrice = parseFloat(this.addServiceForm.value.specialPrice);
      this.http.putRequest('admin/provider/service/'+ this.data?._id, data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Service Updated successfully');
           this.dialog.closeAll()
           this.cm.servicelist$.next(true)
          }
       },(err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      })
    }
  }
  else if(this.role == 'PROVIDER'){
    this.submitted = true;
    if(this.deleteList.length==0){
      this.cm.presentsToast('error','top-right','Please select Staff')
    }
    if(this.submitted && this.addServiceForm.valid && this.deleteList.length>0){
      let data1 = this.addServiceForm.value;
      data1.staff = this.deleteList;
      data1.price = parseFloat(this.addServiceForm.value.price);
      data1.specialPrice = parseFloat(this.addServiceForm.value.specialPrice);
      this.http.putRequest('provider/service/'+ this.data?._id, data1).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.cm.presentsToast('success','top-right','Service Updated successfully');
           this.dialog.closeAll()
           this.cm.servicelist$.next(true)
          }
       },(err: any) => {
        console.log("err", err);
        this.cm.presentsToast('error', 'top-right', err.error.message);
      })
    }
  }}

  selectAll(checked){
    console.log(checked)
    this.showImageCheck = checked
    this.listOfStaff.map(item=>item.check = checked);
    console.log(this.listOfStaff,'llll')
    if(checked){
    this.deleteList = this.listOfStaff.map(item=>item._id);
    }
    else{
      this.deleteList=[];
    } 
  }



  selectparticular(checked,item){
    console.log(checked,item.check,'1')
    this.singleCheck = checked
    item.check = checked
    if(item.check){
      this.deleteList.push(item._id)
    }
    else{
      if(this.deleteList.length>0){
        if(this.deleteList.includes(item._id)){
          this.deleteList.splice(this.deleteList.findIndex(data=>data===item._id),1);

        }
      }
    }
    
    console.log(checked,item.check,'2')
    console.log(this.deleteList,'uu')
  
  
  }




}
 