<div class="mn_pp">
    <div class="mn_icn_cr" (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></div>
    <h3 *ngIf="!data"> Add New Service</h3>
    <h3 *ngIf="data"> Update New Service</h3>
    <form [formGroup]="addServiceForm">
        <div class="login-form comn_frm as_stvs">
            <div class="form-group">
                <label class="control-label"> Service Category </label>
                <mat-form-field floatLabel="never">
                    <mat-select formControlName="serviceCategory" placeholder="Please Select Required Field"
                        (selectionChange)="categoryChange($event.value)">
                        <mat-option *ngFor="let cat of allServiceCategory" [value]="cat._id">
                            {{ cat?.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf=" submitted && f.serviceCategory.errors?.required">
                    Service Category  Required
                </mat-error>
            </div>
            <div class="form-group">
                <label class="control-label">Select Service </label>
                <mat-form-field floatLabel="never">
                    <mat-select formControlName="subService" placeholder="Please Select Required Field"
                    (selectionChange)="serviceChange($event.value)">
                        <mat-option *ngFor="let ser of allService" [value]="ser._id">
                            {{ ser?.name }}
                        </mat-option>
                    </mat-select>                 
                </mat-form-field>
                <mat-error *ngIf="submitted && f.subService.errors?.required">
                    Service is Required
                </mat-error>
            </div>
            <div class="form-group">
                <label class="control-label">Duration </label>
                <mat-form-field floatLabel="never">
                    <mat-select formControlName="duration" placeholder="Please Select Required Field">
                        <mat-option *ngFor="let dur of allDuration" [value]="dur._id">
                            {{ dur?.duration }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf=" submitted && f.duration.errors?.required">
                    Duration Is Required
                </mat-error>
            </div>
            <div class="form-group ">
                <div class="stf_opn">
                    <label class="control-label">Select Staff</label>
                    <label class="control-label" *ngIf="listOfStaff?.length>1" style="margin-left: 286px;">Select All</label>
                    <mat-checkbox *ngIf="listOfStaff?.length>1" class="example-margin" color="warn" (change)="selectAll($event.checked)">
                    </mat-checkbox>
                </div>

                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig1">
                    <div ngxSlickItem class="slide" *ngFor="let staff of listOfStaff">
                        <div class="sp_ctg_pt">
                            <a>
                                <mat-checkbox class="example-margin" color="warn" [checked]="staff.check" (change)="selectparticular($event.checked,staff)">
                                </mat-checkbox>
                                <figure class="top_rated" [class]="staff.check ? 'top_rated active':'top_rated'">
                                    <i class="fas fa-check-circle"></i>
                                    <img [src]="staff.image"  (error)="handleUImageErr($event, staff)" alt="Image">
                                    
                                </figure>
                                <p style="font-size: 14px">{{staff?.firstName}} {{staff?.lastName}}</p>
                            </a>
                        </div>

                    </div>
                </ngx-slick-carousel>

            </div>
            <div class="frm_ipt">
                <div class="form-group">
                    <label class="control-label">Price</label>
                    <input appNoSpaceAtFirst numbersOnly type="text" id="name" class="form-control" formControlName="price" />
                    <mat-error *ngIf=" submitted && f.price.errors?.required">
                        Price is Required
                    </mat-error>
                </div>
                <div class="form-group">
                    <label class="control-label">Special Price</label>
                    <input appNoSpaceAtFirst numbersOnly type="text" id="name" class="form-control" formControlName="specialPrice" />
                </div>
            </div>


            <div class="btn_main" *ngIf="!data">
                <button class="btn btn-primary" type="button" (click)="add()"> Save</button>
            </div>
            <div class="btn_main" *ngIf="data">
                <button class="btn btn-primary" type="button" (click)="update()"> Update</button>
            </div>
        </div>


    </form>
</div>