import { ThrowStmt } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Console } from 'console';
import $ from 'jquery';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import Swal from 'sweetalert2';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  subAdminPermissionArray: any;
  listOfNotification: any;
  bookingNo: any;
  constructor(
    private router: Router,
    private http: HttpService,
    public cm: CommonService,
    private socket: SocketService,
  ) {
    this.providerid = localStorage.getItem('userId');
    this.socket.initSocket();
  }

  sidebarItem = [
    {
      path: '/pages/dashboard',
      title: 'Dashboard',
      class: 'single',
      icon: 'assets/img/svg/home.svg',
    },
    {
      path: '',
      title: 'Manage Customer',
      icon: 'assets/img/svg/user.svg',
      class: 'single',
    },

    {
      path: '',

      title: 'Manage Catalogue',
      icon: 'assets/img/svg/catalog.svg',
      class: 'subchild',

      children: [
        {
          path: '/pages/category',
          title: 'Manage Category',
          icon: 'assets/img/svg/catgory.svg',
          class: 'child',
        },
        {
          path: '/pages/products',
          title: 'Products',
          icon: 'assets/img/svg/cart_icon.svg',
          class: 'child',
        },
        {
          path: '/pages/brands',
          title: 'Brands',
          icon: 'assets/img/svg/tag.svg',
          class: 'child',
        },
        {
          path: '/pages/attribute',
          title: 'Attribute',
          icon: 'assets/img/svg/attribute.svg',
          class: 'child',
        },
        {
          path: '/pages/review',
          title: 'Review',
          icon: 'assets/img/svg/review.svg',
          class: 'child',
        },
      ],
    },

    {
      path: '/pages/vendor/vendordashboard',

      title: 'Manage Vendor',
      icon: 'assets/img/svg/user.svg',
      class: 'subchild',

      children: [
        {
          path: '/pages/vendor/vendordashboard',
          title: 'Manage Vendor',
          icon: 'assets/img/svg/Analytics.svg',
          class: 'child',
        },
        {
          path: '/pages/vendor/vendorprofile/edit/1',
          title: 'Vendor Profile',
          icon: 'assets/img/svg/user.svg',
          class: 'child',
        },
        {
          path: '/pages/vendor/orderbyvendor',
          title: ' Orders',
          icon: 'assets/img/svg/order.svg',
          class: 'child',
        },
        // {
        //   path: '/pages/marketing/discount-coupons',
        //   title: 'Coupons',
        //   icon: 'assets/img/svg/coupan.svg',
        //   class: 'child',
        // },

        {
          path: '/pages/products',
          title: ' Shops',
          icon: 'assets/img/svg/shop.svg',
          class: 'child',
        },
        {
          path: '/pages/vendor/transactionbyvendor',
          title: ' Transactions',
          icon: 'assets/img/svg/trans.svg',
          class: 'child',
        },
        {
          path: '/pages/vendor/paymentbyvendor',
          title: ' Payments',
          icon: 'assets/img/svg/payment.svg',
          class: 'child',
        },
        {
          path: '/pages/vendor/settingbyvendor',
          title: ' Settings',
          icon: 'assets/img/svg/setting.svg',
          class: 'child',
        },
      ],
    },

    {
      path: '/pages/serviceprovider',

      title: 'Manage Service Provider',
      icon: 'assets/img/png/images.png',
      class: 'subchild',

      children: [
        {
          path: '/pages/serviceprovider',
          title: 'Manage Service Provider',
          icon: 'assets/img/svg/Analytics.svg',
          class: 'child',
        },

        {
          path: '/pages/serviceprovider/spmanage/spedit/edit/2',
          title: 'Manage Profile',
          icon: 'assets/img/svg/user.svg',
          class: 'child',
        },
        {
          path: '/pages/serviceprovider/appointments',
          title: 'Manage Appointments',
          icon: 'assets/img/svg/apponitment.svg',
          class: 'child',
        },

        {
          path: '/pages/serviceprovider/sidebarStaff',
          title: 'Manage Staff',
          icon: 'assets/img/svg/staff.svg',
          class: 'child',
        },
        {
          path: '/pages/serviceprovider/sidebarService',
          title: 'Manage Services',
          icon: 'assets/img/jpg/service.jpg',
          class: 'child',
        },
        {
          path: '/pages/serviceprovider/sidebarContent',
          title: 'Manage content',
          icon: 'assets/img/svg/content.svg',
          class: 'child',
        },
        // {
        //   path: '/pages/serviceprovider/coupons',
        //   title: 'Manage Coupons',
        //   icon: 'assets/img/svg/coupan.svg',
        //   class: 'child',
        // },
        {
          path: '/pages/serviceprovider/transactions',
          title: 'Manage Transaction',
          icon: 'assets/img/svg/trans.svg',
          class: 'child',
        },
        {
          path: '/pages/serviceprovider/payment-details',
          title: 'Manage Payment',
          icon: 'assets/img/svg/payment.svg',
          class: 'child',
        },
        {
          path: '/pages/serviceprovider/spmanage/spreview',
          title: 'Manage Review',
          icon: 'assets/img/svg/review.svg',
          class: 'child',
        },
        {
          path: '/pages/serviceprovider/setting',
          title: 'SP Setting',
          icon: 'assets/img/svg/setting.svg',
          class: 'child',
        },

      ],
    },

    {
      path: '/pages/influencer/influencerdashboard',

      title: 'Manage Influencer',
      icon: 'assets/img/svg/user.svg',
      class: 'subchild',

      children: [
        {
          path: '/pages/influencer/influencerdashboard',
          title: 'Manage Dashboard',
          icon: 'assets/img/svg/Analytics.svg',
          class: 'child',
        },
        {
          path: '/pages/influencer/editinfluencer/1',
          title: 'Manage Profile',
          icon: 'assets/img/svg/user.svg',
          class: 'child',
        },
        {
          path: '/pages/products',
          title: 'Manage Product',
          icon: 'assets/img/svg/cart_icon.svg',
          class: 'child',
        },
        {
          path: '/pages/products/shop',
          title: 'Manage Shop',
          icon: 'assets/img/svg/shop.svg',
          class: 'child',
        },
        {
          path: '/pages/influencer/contentbyinfluencer',
          title: 'Manage Content',
          icon: 'assets/img/svg/content.svg',
          class: 'child',
        },
        {
          path: '/pages/influencer/adminpayment',
          title: 'My Earning',
          icon: 'assets/img/svg/payment.svg',
          class: 'child',
        },
        {
          path: '/pages/influencer/orderbyinfluencer',
          title: 'My Order',
          icon: 'assets/img/svg/order.svg',
          class: 'child',
        },
        //////////
        {
          path: '/pages/influencer/myearnings',
          title: 'My Transaction',
          icon: 'assets/img/svg/trans.svg',
          class: 'child',
        },

      ],
    },

    {
      path: '',
      title: 'Manage Marketing',
      icon: 'assets/img/svg/user.svg',
      class: 'subchild',

      children: [
        {
          path: '/pages/marketing',
          title: 'Bundles Promotion',
          class: 'child',
        },
        {
          path: '/pages/marketing/discount-coupons',
          title: 'Coupons',
          icon: 'assets/img/svg/home.svg',
          class: 'child',
        },
      ],
    },

    // {
    //   path: '/pages/customersupport',
    //   title: 'Manage Customer Support',
    //   class: 'single',
    //   icon: 'assets/img/svg/custmr_sppt.svg',
    // },
    {
      path: '/pages/notification',
      title: 'Manage Notification',
      class: 'single',
      icon: 'assets/img/svg/notifctn.svg',
    },
    // {
    //   path: '/pages/analytics/analytics',
    //   title: 'Analytics',
    //   class: 'single',
    //   icon: 'assets/img/svg/Analytics.svg',
    // },
    {
      path: '/pages/geofencing',
      title: 'Manage Geofencing',
      class: 'single',
      icon: 'assets/img/svg/map.svg',
    },
    {
      path: '/pages/banner',
      title: 'Manage Banner',
      class: 'single',
      icon: 'assets/img/svg/banner.svg',
    },
    {
      path: '/pages/subadmin',
      title: 'Manage Sub-Admin',
      class: 'single',
      icon: 'assets/img/svg/user.svg',
    },

    {
      path: '',
      title: 'Manage CMS',
      icon: 'assets/img/svg/setting.svg',
      class: 'subchild',

      children: [
        {
          path: '/pages/managecms',
          title: 'Contact Support',
          // icon: "assets/img/svg/home.svg",
          class: 'child',
        },
        {
          path: '/pages/managecms/legal',
          title: 'legal',
          // icon: "assets/img/svg/home.svg",
          class: 'child',
        },
        {
          path: '/pages/managecms/privacy-policy',
          title: 'Privacy Policy',
          // icon: "assets/img/svg/home.svg",
          class: 'child',
        },
        {
          path: '/pages/managecms/faq',
          title: 'FAQs',
          // icon: "assets/img/svg/home.svg",
          class: 'child',
        },
      ],
    },
  ];
  showDropBox: boolean = false;
  email: string;
  emailInfluencer: string = 'influencer@yopmail.com';
  emailVendor: string = 'vendor@yopmail.com';
  emailProvider: string = 'provider@yopmail.com';
  emailAdmin: string = 'undefined';
  enableItem: boolean = false;
  enableItemVendor: boolean = false;
  enableItemProvider: boolean = false;
  enableItemAdmin: boolean = false;
  role: any;
  profileData: any;
  newSide: any[] = [];
  roleData: any[] = [];
  isSingleModule: boolean = false;
  providerid: any;

  ngOnInit(): void {
    this.initSocket()
    this.socketforappointmentforProvider()
    $(document).on('click', '#menu-toggle', function (event) {
      event.preventDefault();
      $('#wrapper').toggleClass('toggled');
      $('body').toggleClass('body');
    });
    $(window).resize(function (e) {
      if ($(window).width() <= 768) {
        $('#wrapper').removeClass('toggled');
      } else {
        $('#wrapper').addClass('toggled');
      }
    });
    if (localStorage.getItem('role')) {
      this.role = localStorage.getItem('role');
      this.providerid = localStorage.getItem('userId');
      this.getProfile()
    }

    this.cm.imageFlag.subscribe(res => {
      if (res === 'Flag') {
        this.getProfile()
      }
    })

    this.setUpSideBar();
    if (this.role == 'SUB') {
      this.subAdminPermissionArray = JSON.parse(localStorage.getItem('permissionArray'))
      console.log(this.subAdminPermissionArray, 'aaaa')
      console.log(this.sidebarItem, 'ssss')
      let subData = [];
      this.subAdminPermissionArray.filter((res => {
        this.sidebarItem.filter((mainres => {
          if (res.module == mainres.title) {
            if (res.view)
              subData.push(mainres)
          }
        }))
      }))
      this.sidebarItem = []
      this.sidebarItem = subData;
    }
    this.getNotification()


  }

  initSocket() {
    const notificationSound = new Audio('/assets/sounds/notif.mp3');
    console.log(notificationSound, 'nnn')
    this.socket.onEvent("newOrder").subscribe(res => {
      console.log(res, 1);
      notificationSound.load()
      notificationSound.play()
      this.cm.presentsToast('success', 'top-end', 'New Order Received');
    });
  }

  socketforappointmentforProvider() {
    const notificationSound = new Audio('/assets/sounds/notif.mp3');
    console.log(notificationSound, 'nnn')
    this.socket.onEvent("newBooking").subscribe(res => {
      console.log(res, 1);
      this.bookingNo = res?.bookingNo
      notificationSound.load()
      notificationSound.play()
      this.cm.presentsToast('success', 'top-end', 'New Booking Received', this.bookingNo);
    });
  }
  getProfile() {
    this.role = localStorage.getItem('role');
    this.http.getRequestWithoutbody(`${this.role == 'ADMIN' ? 'admin'
      : this.role == 'VENDOR' ? 'vendor'
        : this.role == 'PROVIDER' ? 'provider'
          : this.role == 'INFLUENCER' ? 'influencer'
            : 'admin'}/profile`
    ).subscribe((res: any) => {
      console.log(4);
      this.profileData = res['data'];
    });
  }

  setUpSideBar() {
    let roleWiseSidebar = [...this.sidebarItem];
    // let dashboard = roleWiseSidebar.shift();
    this.sidebarItem = [];
    if (this.role == 'INFLUENCER') {
      this.roleData.push(
        roleWiseSidebar.find((item) => item.title === 'Manage Influencer')
      );
      this.sidebarItem = [...this.roleData];
      this.sidebarItem[0].children.slice(0, 1);
      this.setUpSingleTab();
    } else if (this.role == 'VENDOR') {
      this.roleData.push(
        roleWiseSidebar.find((item) => item.title === 'Manage Vendor')
      );
      this.sidebarItem = [...this.roleData];
      this.sidebarItem[0].children.slice(0, 1);

      this.setUpSingleTab();
    } else if (this.role == 'PROVIDER') {
      roleWiseSidebar.filter((item1) => {
        if (item1.title === 'Manage Service Provider') {
          item1.children.splice(
            item1.children.findIndex(
              (data) =>
                data.title === 'SP Setting' || data.title === 'Manage Review'
            ),
            2
          );
        }
      });
      this.roleData.push(
        roleWiseSidebar.find((item) => item.title === 'Manage Service Provider')
      );

      this.sidebarItem = [...this.roleData];
      this.sidebarItem[0].children.slice(0, 1);
      this.setUpSingleTab();

    } else {
      this.sidebarItem = [
        {
          path: '/pages/dashboard',
          title: 'Dashboard',
          class: 'single',
          icon: 'assets/img/svg/home.svg',
        },
        {
          title: 'Manage Customer',
          icon: 'assets/img/svg/user.svg',
          class: 'single',
          path: '/pages/customer/listOfcustomer',
        },

        {
          path: '',
          title: 'Manage Catalogue',
          icon: 'assets/img/svg/catalog.svg',
          class: 'subchild',
          children: [
            {
              path: '/pages/category',
              title: 'Manage Category',
              icon: 'assets/img/svg/catgory.svg',
              class: 'child',
            },
            {
              path: '/pages/products',
              title: 'Products',
              icon: 'assets/img/svg/cart_icon.svg',
              class: 'child',
            },
            {
              path: '/pages/brands',
              title: 'Brands',
              icon: 'assets/img/svg/tag.svg',
              class: 'child',
            },
            {
              path: '/pages/attribute',
              title: 'Attribute',
              icon: 'assets/img/svg/attribute.svg',
              class: 'child',
            },
            {
              path: '/pages/review',
              title: 'Review',
              icon: 'assets/img/svg/review.svg',
              class: 'child',
            },
            {
              path: '/pages/label',
              title: 'Label',
              icon: 'assets/img/svg/review.svg',
              class: 'child',
            },
          ],
        },
        {
          path: '/pages/vendor/vendororder',
          title: 'Manage Orders',
          icon: 'assets/img/svg/order.svg',
          class: 'single',
        },

        {
          path: '/pages/vendor/vendordashboard',
          title: 'Manage Vendor',
          icon: 'assets/img/svg/user.svg',
          class: 'subchild',

          children: [
            {
              path: '/pages/vendor',
              title: 'View Vendors',
              icon: 'assets/img/svg/user.svg',
              class: 'child',
            },
            {
              path: '/pages/vendor/transactionlist',
              title: 'Manage Transactions',
              icon: 'assets/img/svg/coupan.svg',
              class: 'child',
            },
            {
              path: '/pages/vendor/adminpayment',
              title: 'Manage Payments',
              icon: 'assets/img/svg/trans.svg',
              class: 'child',
            },
            {
              path: '/pages/vendor/rating',
              title: 'Manage Ratings',
              icon: 'assets/img/svg/review.svg',
              class: 'child',
            },
            {
              path: '/pages/vendor/vendorsetting',
              title: 'Vendor Setting',
              icon: 'assets/img/svg/setting.svg',
              class: 'child',
            },
          ],
        },

        {
          path: '/pages/serviceprovider',
          title: 'Manage Service Provider',
          icon: 'assets/img/png/images.png',
          class: 'subchild',

          children: [
            {
              path: '/pages/serviceprovider/spmanage',
              title: 'Manage SP',
              icon: 'assets/img/png/images.png',
              class: 'child',
            },
            {
              path: '/pages/serviceprovider/appointments',
              title: 'Manage Appointments',
              icon: 'assets/img/svg/apponitment.svg',
              class: 'child',
            },
            {
              path: '/pages/serviceprovider/transactions',
              title: 'Manage Transaction',
              icon: 'assets/img/svg/trans.svg',
              class: 'child',
            },
            {
              path: '/pages/serviceprovider/payment-details',
              title: 'Manage Payment',
              icon: 'assets/img/svg/payment.svg',
              class: 'child',
            },
            {
              path: '/pages/serviceprovider/spmanage/spreview',
              title: 'Manage Review',
              icon: 'assets/img/svg/review.svg',
              class: 'child',
            },
            {
              path: '/pages/serviceprovider/setting',
              title: 'SP Setting',
              icon: 'assets/img/svg/setting.svg',
              class: 'child',
            },

          ],
        },

        {
          path: '/pages/influencer/influencerdashboard',
          title: 'Manage Influencer',
          icon: 'assets/img/png/influencer.png',
          class: 'subchild',

          children: [
            {
              path: '/pages/influencer',
              title: 'View Influencer',
              icon: 'assets/img/png/influencer.png',
              class: 'child',
            },
            {
              path: '/pages/influencer/vieworder',
              title: 'View Order',
              icon: 'assets/img/svg/order.svg',
              class: 'child',
            },

            {
              path: '/pages/influencer/managecontent/',
              title: 'Manage Content',
              icon: 'assets/img/svg/content.svg',
              class: 'child',
            },
            {
              path: '/pages/influencer/myearnings',
              title: 'Manage Transaction',
              icon: 'assets/img/svg/trans.svg',
              class: 'child',
            },
            {
              path: '/pages/influencer/adminpayment',
              title: 'Manage Payment',
              icon: 'assets/img/svg/payment.svg',
              class: 'child',
            },
            {
              path: '/pages/influencer/influencersetting',
              title: 'Influencers Settings',
              icon: 'assets/img/svg/setting.svg',
              class: 'child',
            },
          ],
        },

        {
          path: '',
          title: 'Manage Marketing',
          icon: 'assets/img/png/marketing.png',
          class: 'subchild',

          children: [
            {
              path: '/pages/marketing/discount-coupons',
              title: 'Coupons',
              icon: 'assets/img/svg/coupan.svg',
              class: 'child',
            },
          ],
        },
        {
          path: '',
          title: 'Manage Partner',
          icon: 'assets/img/png/partner-5.png',
          class: 'subchild',

          children: [
            {
              path: '/pages/partner',
              title: 'Vendor',
              icon: 'assets/img/svg/user.svg',
              class: 'child',
            },
            {
              path: '/pages/partner/partnersp',
              title: 'Service Provider',
              icon: 'assets/img/svg/user.svg',
              class: 'child',
            },
            {
              path: '/pages/partner/becomeinfluencer',
              title: 'Influencer',
              icon: 'assets/img/svg/user.svg',
              class: 'child',
            },
          ],
        },
        // {
        //   path: '/pages/customersupport',
        //   title: 'Manage Customer Support',
        //   class: 'single',
        //   icon: 'assets/img/svg/custmr_sppt.svg',
        // },
        {
          path: '/pages/notification',
          title: 'Manage Notification',
          class: 'single',
          icon: 'assets/img/svg/notifctn.svg',
        },
        {
          path: '/pages/geofencing',
          title: 'Manage Geofencing',
          class: 'single',
          icon: 'assets/img/svg/map.svg',
        },
        {
          path: '/pages/banner',
          title: 'Manage Banner',
          class: 'single',
          icon: 'assets/img/svg/banner.svg',
        },
        {
          path: '/pages/subadmin',
          title: 'Manage Sub-Admin',
          class: 'single',
          icon: 'assets/img/svg/user.svg',
        },

        {
          path: '',
          title: 'Manage CMS',
          icon: 'assets/img/svg/setting.svg',
          class: 'subchild',

          children: [
            {
              path: '/pages/managecms/contact-support',
              title: 'Contact Support',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
            {
              path: '/pages/managecms/about-us',
              title: 'About Us',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
            {
              path: '/pages/managecms/legal',
              title: 'Legal',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
            {
              path: '/pages/managecms/term-condition',
              title: 'Terms and Condition',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
            {
              path: '/pages/managecms/press-and-media',
              title: 'Press and Media',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
            {
              path: '/pages/managecms/privacy-policy',
              title: 'Privacy Policy',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
            {
              path: '/pages/managecms/shipping-and-delivery',
              title: 'Shipping and Delivery',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
            {
              path: '/pages/managecms/returns-and-refund',
              title: 'Returns and Refund',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
            {
              path: '/pages/managecms/order-cancellation',
              title: 'Order Cancellation',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
            {
              path: '/pages/managecms/payments',
              title: 'Payments',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
            {
              path: '/pages/managecms/offer',
              title: 'Offers',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
            {
              path: '/pages/managecms/account',
              title: 'Account',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
            {
              path: '/pages/managecms/other',
              title: 'Other',
              // icon: "assets/img/svg/home.svg",
              class: 'child',
            },
          ],
        },
      ];
    }
  }
  handleToggleTabs(path) {
    if (path !== '') {
      this.router.navigate([path]);
    }
  }

  setUpSingleTab() {
    this.isSingleModule = this.sidebarItem.length === 1 ? true : false;
    console.log(this.isSingleModule);
    let newSideBar = [...this.roleData];
    console.log(newSideBar);
    newSideBar.filter((item) => {
      item.children.filter((child) => {
        this.newSide.push(child);
      });
    });
  }

  logout() {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, logout!',
      cancelButtonText: 'No, stay here only',
    }).then((result) => {
      if (result.isConfirmed) {
        (this.role == 'ADMIN' || this.role == 'SUB'
          ? this.http.postRequest('admin/logout', '')
          : this.role == 'VENDOR'
            ? this.http.postRequest('vendor/logout', '')
            : this.role == 'PROVIDER'
              ? this.http.postRequest('provider/logout', '')
              : this.role == 'INFLUENCER'
                ? this.http.postRequest('influencer/logout', '')
                : this.http.postRequest('' + '', '')
        ).subscribe((response: any) => {
          if (response.statusCode == 200) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('permissionArray')
            this.router.navigate(['/auth/login']);
            this.cm.presentsToast('success', 'top-right', 'Logout Successfully');
          }
        },
          (err: any) => {
            console.log(err, 'err');
            this.cm.presentsToast('error', 'top-right', err.error.message);
          });
      }
    });
  }

  getNotification() {
    (this.role == 'ADMIN' || this.role == 'SUB'
      ? this.http.getRequestWithoutbody('admin/notifications')
      : this.role == 'VENDOR'
        ? this.http.getRequestWithoutbody('vendor/notifications')
        : this.role == 'PROVIDER'
          ? this.http.getRequestWithoutbody('provider/notifications')
          : this.role == 'INFLUENCER'
            ? this.http.getRequestWithoutbody('influencer/notifications')
            : this.http.getRequest('' + '', '')
    ).subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.listOfNotification = response.data.data
      }
    },
      (err: any) => {
        console.log(err, 'err');
        this.cm.presentsToast('error', 'top-right', err.error.message);
      }
    );
  }


}
