import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';


@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.scss']
})
export class AddStaffComponent implements OnInit {
  allspecialization: any;
  allprovider: any;
  errorImage: boolean;
  logoImage: any;
  addStaffForm: FormGroup;

  data: any
  timeSlots: Array<any> = [

    {
      day: "monday",
      start: "",
      end: "",
      isEnabled: false,
      isView: false,
      isError: false

    },
    {
      day: "tuesday",
      start: "",
      end: "",
      isEnabled: false,
      isView: false,
      isError: false

    },
    {
      day: "wednesday",
      start: "",
      end: "",
      isEnabled: false,
      isView: false,
      isError: false
    },
    {
      day: "thursday",
      start: "",
      end: "",
      isEnabled: false,
      isView: false,
      isError: false

    },
    {
      day: "friday",
      start: "",
      end: "",
      isEnabled: false,
      isView: false,
      isError: false

    },
    {
      day: "saturday",
      start: "",
      end: "",
      isEnabled: false,
      isView: false,
      isError: false

    },
    {
      day: "sunday",
      start: "",
      end: "",
      isEnabled: false,
      isView: false,
      isError: false

    },
  ];
  submitted: boolean = false
  providerid: string;
  providertype: string;
  role: string;
  opentime: any;
  closetime: any;
  isTimeSlot: boolean = false;
  openDuration: any;
  closeDuration: any;
  toggleday:boolean = false
  constructor(private dialog: MatDialog, private http: HttpService, private fb: FormBuilder, private cm: CommonService) { }
  close() {
    this.dialog.closeAll()

  }
  ngOnInit(): void {
    console.log(this.data);
    this.role = localStorage.getItem("role")
    this.providerid = localStorage.getItem('providerId')
    this.providertype = localStorage.getItem('providertype')

    this.addStaffForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(50)]],
      lastName: [''],
      firstName_ar: ['', [Validators.required, Validators.maxLength(50)]],
      lastName_ar: [''],
      specialization: ['', [Validators.required]],
      services: ['', [Validators.required,]],

    })
    this.getSpecialization()
    this.getServiceProvider()
    if (this.data) {
      this.setValue();
    }
   
  }

  setValue() {
    this.addStaffForm.patchValue(this.data)
    this.logoImage = this.data.image,
      this.addStaffForm.patchValue({
        specialization: this.data?.specialization?._id
      })
    let serviceArray = []
    for (var id of this.data?.services) {
      serviceArray.push(id._id)
    }
    this.addStaffForm.get('services').setValue(serviceArray)
    this.timeSlots.filter((res) => {
      console.log(res);     
      res.start = this.data[res["day"]]["start"]
      res.end = this.data[res["day"]]['end']    
      res.isEnabled = this.data[res["day"]]['isEnabled']
      if (Number(res.start.split(":").join('')) >= Number(res.end.split(":").join('')) && Number(res.end.split(":").join('')) !== 0 && Number(res.start.split(":").join('')) !== 0) {
        res["isError"] = true;

      }
      else {
        res["isError"] = false;
      }
      if(res.start && res.end){
        res.isView = true
      }})
    console.log(this.timeSlots);    
  }
  get f(): any {
    return this.addStaffForm.controls
  }


  async uploadImage(file) {
    let formData = new FormData();
    formData.append("upload", file);
    let res = await this.http
      .postRequest(`${this.role == 'ADMIN' ? 'admin' : this.role == 'PROVIDER' ? 'provider' : 'admin'}/upload`, formData)
      .toPromise();
    return res["data"];
  }

  async fileHandler(event) {
    const File = event.target.files[0];
    if (
      File.type == "image/png" ||
      File.type == "image/jpg" ||
      File.type == "image/jpeg"
    ) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          this.logoImage = event.target.result;
        };
      }
      this.errorImage = false;
      this.logoImage = await this.uploadImage(File);
    } else {
      this.errorImage = true;
      this.cm.presentsToast('error', 'top-right', "Only PNG, JPEG ,JPG are Allowed");
    }
  }

  getSpecialization() {
    if (this.role == 'ADMIN' || this.role == 'SUB') {
      this.http.getRequest("admin/provider/specialization", '').subscribe(
        (res: any) => {
          this.allspecialization = res.data;
        },
        (error) => console.log(error)
      )}
    else {
      this.http.getRequest("provider/specialization", '').subscribe(
        (res: any) => {
          this.allspecialization = res.data;},
        (error) => console.log(error)
      )}}

  getServiceProvider() {
    if (this.role == 'ADMIN' || this.role == 'SUB') {
      this.http.getRequest("admin/provider/staff/services/list/" + this.providerid, '').subscribe(
        (res: any) => {
          this.allprovider = res.data;
        },
        (error) => console.log(error)
      );}
    else {
      this.http.getRequest("provider/services", '').subscribe(
        (res: any) => {
          this.allprovider = res.data;
        },
        (error) => console.log(error))
}}

  handleTimeSlot() {
    let isTimeSlot = false;
    if (this.timeSlots.map(item => item.start != '' ).includes(true)) {
      isTimeSlot = true;
    }
    else {
      this.cm.presentsToast('error', 'top-right', 'Add Minimum One Working Day for the Staff')
      isTimeSlot = false;
    }
    return isTimeSlot;
  }
  payloadData() {
    let data = this.addStaffForm.value
    data.image = this.logoImage;
    data.provider = this.providerid
    for (let key in this.timeSlots) {
      data[this.timeSlots[key]['day']] = {
        start: this.timeSlots[key]['start'],
        end: this.timeSlots[key]['end'],
        isEnabled: this.timeSlots[key]["isEnabled"]
      }
    }
    return data;
  }

  add() {
    this.submitted = true;
    if (this.role == 'ADMIN' || this.role == 'SUB') {
      this.isTimeSlot = this.handleTimeSlot();
      if (this.isTimeSlot && this.submitted && this.addStaffForm.valid) {
        if (!this.timeSlots.map(item => item.isError).includes(true)) {       
          let data;
          data = this.payloadData();
          this.http.postRequest('admin/provider/staff', data).subscribe((res) => {
            if (res['statusCode'] == 200) {
              this.cm.presentsToast('success', 'top-right', 'Staff Added successfully');
              this.dialog.closeAll()
              this.cm.stafflist$.next(true)
            }
          }, (err: any) => {
            console.log("err", err);
            this.cm.presentsToast('error', 'top-right', err.error.message);
          })
        }
      }
        else {
          this.cm.presentsToast('error', 'top-right', 'Please Select Valid Time');
        }
      }
    
    else {
      this.isTimeSlot = this.handleTimeSlot();
      if (this.isTimeSlot && this.submitted && this.addStaffForm.valid) {
        if (!this.timeSlots.map(item => item.isError).includes(true)) { 
          let data;
          data = this.payloadData();
          delete data.provider
          this.http.postRequest('provider/staff', data).subscribe((res) => {
            if (res['statusCode'] == 200) {
              this.cm.presentsToast('success', 'top-right', 'Staff Added successfully');
              this.dialog.closeAll()
              this.cm.stafflist$.next(true)
            }
          }, (err: any) => {
            console.log("err", err);
            this.cm.presentsToast('error', 'top-right', err.error.message);
          })
        }
        else {
          this.cm.presentsToast('error', 'top-right', 'Please Select Valid Time ');
        }
      }
    }
  }

  update() {
    this.submitted = true;
    this.isTimeSlot = this.handleTimeSlot();
    if (this.isTimeSlot && this.submitted && this.addStaffForm.valid) {
      if (!this.timeSlots.map(item => item.isError).includes(true)) { 
        let data;
        data = this.payloadData();
        if (this.role == 'ADMIN' || this.role == 'SUB') {
          this.http.putRequest('admin/provider/staff/' + this.data?._id, data).subscribe((res) => {
            if (res['statusCode'] == 200) {
              this.cm.presentsToast('success', 'top-right', 'Staff Updated successfully');
              this.dialog.closeAll()
              this.cm.stafflist$.next(true)
            }
          }, (err: any) => {
            console.log("err", err);
            this.cm.presentsToast('error', 'top-right', err.error.message);
          })
        }
        else {
          delete data.provider
          this.http.putRequest('provider/staff/' + this.data?._id, data).subscribe((res) => {
            if (res['statusCode'] == 200) {
              this.cm.presentsToast('success', 'top-right', 'Staff Updated successfully');
              this.dialog.closeAll()
              this.cm.stafflist$.next(true)
            }
          }, (err: any) => {
            console.log("err", err);
            this.cm.presentsToast('error', 'top-right', err.error.message);
          })
        }
      }
      else{
        this.cm.presentsToast('error', 'top-right', 'Please Select Valid Time ');
      }}
  }

  startTime(openTime,item) {
    this.openDuration = Number(openTime.target.value.split(":").join(''))
    console.log(this.openDuration,item,'ii')
    if(item.end){
      item.isView = true
    }}

  endTime(closeTime,item,index) {
    this.closeDuration = Number(closeTime.target.value.split(":").join(''));
    console.log(this.closeDuration,item,'ii')
    if (Number(this.timeSlots[index].start.split(":").join('')) > 0) {
      if (Number(this.timeSlots[index].start.split(":").join('')) >= Number(this.timeSlots[index].end.split(":").join(''))) {
        this.timeSlots[index]["isError"] = true;
      }
      else {
        this.timeSlots[index]["isError"] = false;
      }
    }
    else {
      this.cm.presentsToast('error', 'top-end', 'Enter Start Time first');

    }
    if(item.start){
      item.isView = true
    }}  
}

